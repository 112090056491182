<template>
  <table class="table table-google">
    <thead>
      <tr>
        <th scope="col">Field</th>
        <th scope="col">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>Value</div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="row-fit text-nowrap">Name</td>
        <td>
          <b-form-input
            v-model="field.field_name"
            size="sm"
            autocomplete="off"
            autofocus
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Type</td>
        <td>
          <button-select
            v-model="field.text_type"
            :options="options.text_type"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Label</td>
        <td>
          <button-select
            v-model="field.field_label"
            :options="options.field_label"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Dots</td>
        <td>
          <button-select
            v-model="field.field_dots"
            :options="options.field_dots"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Row</td>
        <td>
          <div class="d-flex gap-2">
            <b-form-input
              v-model="field.field_row"
              type="number"
              size="sm"
            ></b-form-input>
            <plus-button v-model="field.field_row" />
            <minus-button v-model="field.field_row" />
          </div>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Column</td>
        <td>
          <div class="d-flex gap-2">
            <b-form-input
              v-model="field.field_column"
              type="number"
              size="sm"
            ></b-form-input>
            <plus-button v-model="field.field_column" />
            <minus-button v-model="field.field_column" />
          </div>
        </td>
      </tr>
      <tr v-if="field.text_type === 'dropdown'">
        <td class="row-fit text-nowrap">Options</td>
        <td>
          <b-form-select
            v-model="field.select_group_key"
            :options="selectGroups"
            size="sm"
          ></b-form-select>
        </td>
      </tr>
      <tr v-if="field.text_type === 'static'">
        <td class="row-fit text-nowrap">Text</td>
        <td>
          <b-form-input
            v-model="field.text_content"
            size="sm"
            autocomplete="off"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Prefix</td>
        <td>
          <b-form-input
            v-model="field.text_prefix"
            size="sm"
            autocomplete="off"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Suffix</td>
        <td>
          <b-form-input
            v-model="field.text_suffix"
            size="sm"
            autocomplete="off"
          ></b-form-input>
        </td>
      </tr>
      <tr>
        <td class="row-fit text-nowrap">Direction</td>
        <td>
          <button-select
            v-model="field.text_direction"
            :options="options.text_direction"
            size="sm"
          >
          </button-select>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="text-right">
          <a
            href="javascript:void(0)"
            @click="$emit('delete')"
          >
            Delete field
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const PlusButton = () => import('@/components/labels/PlusButton.vue');
const MinusButton = () => import('@/components/labels/MinusButton.vue');

export default {
  name: 'TextForm',
  components: {
    PlusButton,
    MinusButton,
    ButtonSelect,
  },
  props: {
    field: Object,
    selectGroups: Array,
  },
  data() {
    return {
      options: {
        text_type: [
          { text: 'Static', value: 'static' },
          { text: 'Text', value: 'text' },
          { text: 'Dropdown', value: 'dropdown' },
          { text: 'Date', value: 'date' },
          { text: 'Time', value: 'time' },
        ],
        text_direction: [
          { text: 'Left-to-Right', value: 'ltr' },
          { text: 'Right-to-Left', value: 'rtl' },
        ],
        field_label: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
        field_dots: [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ],
      },
    };
  },
  methods: {
    onUpdate() {
      if (!['static', 'date', 'time'].includes(this.field.text_type)) {
        this.field.text_content = '';
      }
      if (this.field.text_type !== 'dropdown') {
        this.field.select_group_key = null;
      }
      if (this.field.text_type === 'date') {
        this.field.text_content = '01.01.0000';
      }
      if (this.field.text_type === 'time') {
        this.field.text_content = '00:00';
      }
    },
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.onUpdate();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
